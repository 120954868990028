<template>
  <v-card
    :to="{ path: `/workplace/${subject.slug}/${action.path}` }"
    outlined
    rounded="xl"
  >
    <div
      :class="`subjectActionCard main-${subject.color}-bg d-flex flex-column justify-center align-center`"
    >
      <v-sheet
        :class="`rounded-circle secondary-${subject.color}-bg d-flex justify-center align-center`"
        elevation="5"
        height="90"
        width="90"
        color="main-blue"
      >
        <div>
          <v-icon :class="`main-${subject.color}-text`" large>{{
            action.icon
          }}</v-icon>
        </div>
      </v-sheet>

      <div class="white--text">
        <h2 class="text-center">{{ action.name }}</h2>
        <h5 class="font-weight-regular text-center" v-if="action.sub">
          {{ action.sub }}
        </h5>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ActionCard",
  props: ["action", "subject"],
};
</script>

<style>
.subjectActionCard {
  min-height: 40vh;
}
</style>
